import React from 'react';
import { Col, Row } from 'react-grid-system';
import HeroBanner from '../components/organisms/heroBanner';
import Typography from '../components/atoms/typography';
import Spacer from '../components/atoms/spacer';
import styled, { css } from 'styled-components';

import truck from '../images/about/truck.png';
import magnifyer from '../images/about/magnifyer.png';
import people from '../images/about/people.png';
import Container from '../components/atoms/container';
import SEO from '../components/seo';
import useScreenClassHelper from '../hooks/useScreenClassHelper';
import bgImage from '../images/panbox-familj.png';

interface IMobile {
  isMobile: boolean;
}

const AboutImage = styled.img<IMobile>`
  position: relative;
  height: 120px;
  z-index: 1;
  ${({ isMobile }) =>
    isMobile &&
    css`
      height: 80px;
    `}
`;

const BoldText = styled(Typography)`
  font-weight: bold;
  color: ${(props) => props.theme.colors.offWhite};
`;

const SubTitle = styled(Typography)`
  font-weight: 300;
`;

const StyledDiv = styled.div<IMobile>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #808080;
  border-radius: 14px;
  opacity: 1;
  height: 70px;
  padding: 0 20px 0 30px;
  margin-left: -25px;
  margin-left: -25px;
  ${({ isMobile }) =>
    isMobile &&
    css`
      height: 55px;
    `}
`;

const ContainerPromise = styled.div`
  display: flex;
  align-items: center;
`;

const HeroBannerImage = styled.img<IMobile>`
  width: 500px;
  margin-bottom: -24px;
  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 320px;
      margin-bottom: -18px;
    `}
`;

const ourPromises = [
  {
    image: people,
    title: 'Trygghet för alla'
  },
  {
    image: magnifyer,
    title: 'Noga utvalda produkter'
  },
  {
    image: truck,
    title: 'Säkra leveranser'
  }
];

const About = () => {
  const { IS_MOBILE } = useScreenClassHelper();

  return (
    <div>
      <SEO title="Om oss" />
      <HeroBanner
        rightPane={
          <>
            <Spacer spacing={IS_MOBILE ? 7 : 4} />
            <HeroBannerImage alt="familj" isMobile={IS_MOBILE} src={bgImage} />
          </>
        }
      />
      <Container>
        <Spacer spacing={3} />
        <Row align="center">
          <Col xs={12} md={7}>
            <Typography color="primary" variant="header">
              Det här är panBox.
            </Typography>
            <Spacer />
            <SubTitle color="textPrimary" variant="h3">
              Vi tar ansvar för alla medborgare.
            </SubTitle>
            <Spacer spacing={3} />
            <Typography>
              Vi startade panBox för att skapa trygghet för alla. Genom att
              försäkra oss om att vi alltid kan leverera relevanta produkter
              till dig och din familj kan vi skydda samhället och minska
              belastningen på vården.
            </Typography>
            <Spacer />
            <Typography>
              Vi tror att vår tjänst och våra produkter kan göra verklig nytta.
              Vi lagerhåller produkterna på ett säkert och enkelt sätt ser vi
              till att tillgången aldrig sinar. Det blir som ett privat
              beredskapslager.
            </Typography>
            <Spacer />
            <Typography>panBox - trygghet i en box.</Typography>
            <Spacer />
            <Typography variant="h4" color="primary">
              ERIK RYDHOLM, VD PANBOX
            </Typography>
            <Spacer spacing={3} />
          </Col>
          <Col>
            <Row justify="between">
              {ourPromises.map((item) => (
                <Col key={item.title} xs={12}>
                  <ContainerPromise>
                    <AboutImage
                      alt="våra löften"
                      isMobile={IS_MOBILE}
                      src={item.image}
                    />
                    <StyledDiv isMobile={IS_MOBILE}>
                      <BoldText variant="h4">{item.title}</BoldText>
                    </StyledDiv>
                  </ContainerPromise>
                  <Spacer />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Row>
          <iframe
            title="vimeo-player"
            src="https://player.vimeo.com/video/599681227?h=14268a3373"
            width="100%"
            height={IS_MOBILE ? '200' : '500'}
            frameBorder="0"
            allowFullScreen
          />
        </Row>
        <Spacer spacing={3} />
      </Container>
    </div>
  );
};

export default About;
